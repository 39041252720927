import React, {Fragment, cloneElement, useState} from 'react';
import {Form} from 'react-final-form';
import {
    CreateButton,
    List,
    Filter,
    FormDataConsumer,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    BooleanField,
    DateField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton,
    TopToolbar,
    required,
    sanitizeListRestProps,
    useListContext,
    useListController,
    useNotify,
    useRefresh,
    useUnselectAll
} from 'react-admin';
import Emitter from '../utils/eventEmitter';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import ExportBasicListToolbarButton from '../components/ExportBasicListToolbarButton';
import ExportActiveProductsButton from '../components/ExportActiveProductsButton';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import InventoryLedgerTable from '../components/InventoryLedgerTable';
import ListEmpty from '../components/ListEmpty';
import ListEventHandler from '../utils/ListEventHandler';
import ProductDetailDialogContainer from '../components/ProductDetailDialogContainer';
import ProductImage from '../components/ProductImage';
import ProductInventoryAdjustmentInput from '../components/ProductInventoryAdjustmentInput';
import ProductLocationsList from '../components/ProductLocationsList';
import ProductLocationsInput from '../components/ProductLocationsInput';
import ProductThumbnail from '../components/ProductThumbnail';
import SelectFromListInput from '../components/SelectFromListInput';
import BulkEditProductSubmitButton from '../components/BulkEditProductSubmitButton';
import {assemblePostTasks} from '../utils/apiFunctions';
import {addLeadingZero} from '../utils/dataFunctions';
import {editButtonColumnObject, showButtonColumnObject, QtyColumnFilter} from '../utils/tableFunctions';
import {PRODUCT_EXPORT_COLUMNS} from '../global/listDefaults';
import {viewStyles} from '../global/styles';
import {makeStyles} from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CategoryIcon from '@material-ui/icons/Category';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import {tableStyles} from '../global/tableStyles';

export const ProductIcon = CategoryIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const validateName = [required()];

const onSaveProgress = (e) => {
};

/**
 * View: List
 */

const ListFilter = (props) => {

    const unselectAll = useUnselectAll();

    const onFilterChange = (e) => {
        if (props.selectedIds.length) {
            unselectAll('Product');
        }
    };

    return (
        <Filter {...props}>
            <TextInput
                key="filter_sku"
                label="Search by Product SKU"
                source="filter_sku"
                placeholder="SKU-00012345"
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className="input-180"
            />
            <TextInput
                key="filter_title"
                label="Search Product Name"
                source="filter_title"
                alwaysOn
                onChange={(e) => onFilterChange(e)}
            />
            <SelectInput
                key="filter_active" source="active" label="Active"
                choices={[
                    {id: true, name: 'Yes'},
                    {id: false, name: 'No'},
                ]}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                allowEmpty={false}
                className="input-90"
            />
            <SelectInput
                key="filter_by" source="filter_by" label="Filter By:"
                choices={[
                    {id: 'client', name: 'Client'},
                    {id: 'show', name: 'Title'},
                    {id: 'product_type', name: 'Category'}
                ]}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                allowEmpty={true}
                className="input-120"
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_product_type_id"
                resourceName="ProductType"
                listLabel="By Category"
                itemPlural="Categories"
                returnType="string"
                listFilters={{
                    active: true
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={true}
                onChangeFunc={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'product_type') ? 'input-select-list' : 'input-hidden'}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_org_id"
                resourceName="Organization"
                listLabel="By Client"
                itemPlural="Clients"
                returnType="string"
                listFilters={{
                    active: true
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={true}
                onChangeFunc={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'client') ? 'input-select-list' : 'input-hidden'}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_show_id"
                resourceName="Show"
                listLabel="By Title"
                itemPlural="Titles"
                returnType="string"
                listFilters={{
                    active: true
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={true}
                onChangeFunc={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'show') ? 'input-select-list' : 'input-hidden'}
            />
        </Filter>
    )
};

// List Actions (Top Toolbar): Filters, Create, Export
const ListActions = (props) => {
    const {
        className,
        ids,
        filters,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    let exportColumns = PRODUCT_EXPORT_COLUMNS;
    const sort = {
        sort_by: props.currentSort.field ?? 'title',
        sort_order: props.currentSort.order ?? 'ASC'
    };
    const today = new Date();
    const todayStr = today.getFullYear() + '-' + addLeadingZero(today.getMonth() + 1) + '-' + addLeadingZero(today.getDate());

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath}/>
            <ExportBasicListToolbarButton
                type="Product"
                label="Export"
                filename={`Parrot-Products-${todayStr}`}
                filterValues={filterValues}
                sort={sort}
                exportColumns={exportColumns}
                disabled={(!ids || ids.length === 0)}
            />
            <ExportActiveProductsButton buttonType="toolbar"/>
        </TopToolbar>
    );
};

// Container for Bulk-editing actions
const ListBulkActionButtons = props => {

    const refresh = useRefresh();

    const [editOption, setEditOption] = useState(null);
    const [selectedType, setSelectedType] = useState({
        id: null,
        name: null
    });
    const [selectedClient, setSelectedClient] = useState({
        id: null,
        name: null
    });
    const [selectedShow, setSelectedShow] = useState({
        id: null,
        name: null
    });
    const [selectedActive, setSelectedActive] = useState(true);

    const onBulkEditOption = (e) => {
        let val = e?.target?.value;
        if (val) {
            setEditOption(val);
        } else {
            setEditOption(null);
        }
    };

    const onTypeSelect = (e) => {
        if (e && e.id && e.name) {
            setSelectedType({
                id: e.id,
                name: e.name
            });
        } else {
            setSelectedType({id: null, name: null});
        }
    };

    const onClientSelect = (e) => {
        if (e && e.id && e.name) {
            setSelectedClient({
                id: e.id,
                name: e.name
            });
        } else {
            setSelectedClient({id: null, name: null});
        }
    };

    const onShowSelect = (e) => {
        if (e && e.id && e.name) {
            setSelectedShow({
                id: e.id,
                name: e.name
            });
        } else {
            setSelectedShow({id: null, name: null});
        }
    };

    const onActiveSelect = (e) => {
        let val = e?.target?.value;
        if (val !== undefined && val !== null) {
            setSelectedActive(val);
        } else {
            setSelectedActive(true);
        }
    };

    const handleCategorySubmit = (e) => {
        e.preventDefault();
    };

    const handleUpdateSuccess = () => {
        setTimeout(function () {
            refresh();
        }, 1800);
    };

    return (
        <>
            <Form
                onSubmit={handleCategorySubmit}
                render={({form, handleSubmit, values}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <span className="input-inline input-220 input-bulk-edit">
                                <SelectInput
                                    key="bulk_edit_option" source="bulk_edit_option" label="Select value to edit:"
                                    choices={[
                                        {id: 'bulk_edit_option_type', name: 'Product Category'},
                                        {id: 'bulk_edit_option_client', name: 'Client + Title'},
                                        {id: 'bulk_edit_option_active', name: 'Active State'}
                                    ]}
                                    allowEmpty={true}
                                    onChange={(e) => onBulkEditOption(e)}
                                />
                            </span>
                            <span className={(editOption === 'bulk_edit_option_type') ? '' : 'input-hidden'}>
                                <SelectFromListInput
                                    inAdminForm={true}
                                    source="bulk_edit_type_id"
                                    resourceName="ProductType"
                                    listLabel="Select a Product Category"
                                    itemPlural="Product Types"
                                    returnType="object"
                                    listFilters={{
                                        active: true
                                    }}
                                    isRequired={false}
                                    size="small"
                                    hasClear={true}
                                    className="input-inline input-280 input-bulk-edit"
                                    onChangeFunc={(e) => onTypeSelect(e)}
                                />
                            </span>
                            <span className={(editOption === 'bulk_edit_option_client') ? '' : 'input-hidden'}>
                                <SelectFromListInput
                                    inAdminForm={true}
                                    source="bulk_edit_client_id"
                                    resourceName="Organization"
                                    listLabel="Select a Client"
                                    itemPlural="Clients"
                                    returnType="object"
                                    listFilters={{
                                        active: true
                                    }}
                                    isRequired={false}
                                    size="small"
                                    hasClear={true}
                                    className="input-inline input-280 input-bulk-edit"
                                    onChangeFunc={(e) => onClientSelect(e)}
                                />
                            </span>
                            <span
                                className={(editOption === 'bulk_edit_option_client' && selectedClient && selectedClient.id) ? '' : 'input-hidden'}>
                                <SelectFromListInput
                                    inAdminForm={true}
                                    source="bulk_edit_show_id"
                                    resourceName="ShowByOrg"
                                    refresh={true}
                                    listLabel="Select a Title"
                                    itemPlural="Titles"
                                    returnType="object"
                                    listFilters={{
                                        active: true,
                                        org_id: selectedClient.id
                                    }}
                                    isRequired={false}
                                    size="small"
                                    hasClear={true}
                                    className="input-inline input-280 input-bulk-edit"
                                    onChangeFunc={(e) => onShowSelect(e)}
                                />
                            </span>
                            <span
                                className={(editOption === 'bulk_edit_option_active') ? 'input-inline input-180 input-bulk-edit' : 'input-hidden'}>
                                <SelectInput
                                    key="bulk_edit_active" source="bulk_edit_active" label="Select Active State:"
                                    choices={[
                                        {id: true, name: 'Active'},
                                        {id: false, name: 'Inactive'},
                                    ]}
                                    defaultValue={true}
                                    allowEmpty={false}
                                    onChange={(e) => onActiveSelect(e)}
                                />
                            </span>
                            <BulkEditProductSubmitButton
                                editOption={editOption}
                                selectedIds={props.selectedIds}
                                selectedCategory={selectedType}
                                selectedClient={selectedClient}
                                selectedTitle={selectedShow}
                                selectedActive={selectedActive}
                                onSuccess={() => handleUpdateSuccess()}
                                {...props}
                            />
                        </form>
                    )
                }}
            >
            </Form>
        </>
    )
};

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Products"><ProductIcon style={viewStyles.avatarSvg}/></Avatar>Products</span>;

// Handle event from List checkbox column
const onToggleItem = (selectedIds, id, e) => {
    Emitter.emit('updateListSelectedIds', {
        selectedIds: selectedIds,
        resource: 'Product',
        id: id,
        checked: e?.target?.checked ?? false
    });
};

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        return row.values[id] >= filterValue;
    })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';

const listSort = {field: 'title', order: 'ASC'};

export const ProductList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const lc = useListController(props);

    const listColumns = [
        {
            Header: () => null,
            id: 'list-select',
            Cell: ({row}) => (
                <Checkbox
                    color="default"
                    checked={(lc.selectedIds.indexOf(row.original.id) !== -1)}
                    onClick={(e) => onToggleItem(lc.selectedIds, row.original.id, e)}
                />
            ),
            width: 80,
            maxWidth: 80,
            disableFilters: true,
            disableSortBy: true
        },
        showButtonColumn,
        editButtonColumn,
        {
            Header: () => null,
            id: 'list-detail',
            Cell: ({row}) => (
                <span className="table-icon-button">
                    <IconButton
                        aria-label="Quick View"
                        title="Quick View"
                        color="primary"
                        onClick={() => {
                            Emitter.emit('selectedProductId', row.original.id);
                        }}
                    >
                            <InfoIcon/>
                        </IconButton>
                </span>
            ),
            width: 80,
            maxWidth: 80,
            ClassName: 'cell-icon',
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'Image',
            id: 'thumb',
            Cell: ({row}) => <ProductThumbnail
                label="Image"
                id={row.original.id}
                title={row.original.title}
                thumbnail={row.original.thumbnail} {...props}/>,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'SKU',
            accessor: 'sku',
            sortType: 'basic',
            Cell: ({row}) => <span style={viewStyles.noWrap}>{(row.original.sku) ? row.original.sku : ''}</span>
        },
        {
            Header: 'Client',
            accessor: 'org_name',
            sortType: 'basic',
            Cell: ({row}) => <span
                style={viewStyles.noWrap}>{(row.original.org_name) ? row.original.org_name : ''}</span>
        },
        {
            Header: 'Title',
            accessor: 'show_name',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.show_name) ? row.original.show_name : ''}</span>
        },
        {
            Header: 'Name',
            accessor: 'title',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.title) ? row.original.title : ''}</span>
        },
        {
            Header: 'Parent Category',
            accessor: 'parent_type_name',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.parent_type_name) ? row.original.parent_type_name : ''}</span>
        },
        {
            Header: 'Category',
            accessor: 'type_name',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.type_name) ? row.original.type_name : ''}</span>
        },
        {
            Header: () => (<span style={tableStyles.tdRight}>Qty</span>),
            accessor: 'qty',
            id: 'qty',
            filter: filterGreaterThan,
            Filter: QtyColumnFilter,
            sortType: 'basic',
            ClassName: 'right',
            width: 80,
            maxWidth: 80,
            Cell: ({row}) => <span>{row.original.qty}</span>
        }
    ];

    return (
        <>
            <List title={ListTitle}
                  sort={listSort}
                  perPage={20}
                  actions={<ListActions/>}
                  filters={<ListFilter/>}
                  filterDefaultValues={{active: true, filter_by: 'client'}}
                  bulkActionButtons={<ListBulkActionButtons/>}
                  pagination={false}
                  empty={<ListEmpty props={{namePlural: 'Products', nameSingle: 'Product', connector: 'a'}}/>}
                  {...props}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.description}
                        tertiaryText={record => record.warehouse_location}
                    />
                ) : (
                    <DataTable
                        columns={listColumns}
                        sort={listSort}
                        listFilters="filterValues"
                        listFilter="filter_by"
                        messageFilter="Search or select a Filter to view Products."
                        messageEmpty=" No Products found."
                        instructions="To edit multiple Products, select checkboxes and choose a function."
                        storeForExport={true}
                        {...props}
                    />
                )}
            </List>
            <ProductDetailDialogContainer/>
            <ListEventHandler/>
        </>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Product"><ProductIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.title}` : 'Product'}</span>;
};

export const ProductShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Product details below. To edit this Product, click Edit above.
                `}
            />
            <TextField label="ID" source="id" className="input-inline input-280"/>
            <TextField label="SKU" source="sku" className="input-inline input-180"/>
            <FieldDivider type="break" margin="bottom"/>
            <TextField label="Name of Product" source="title" className="field-bg"/>
            <FieldDivider type="break" margin="bottom"/>
            <TextField label="Title" source="show_name" className="input-inline input-280"/>
            <TextField label="Product Category" source="type_display_name" className="input-inline input-320"/>
            <FieldDivider type="break" margin="bottom"/>
            <ProductImage renderType="show" label="Product Image" allowUpload={false} {...props}/>
            <TextField label="Description" source="description" className="field-bg"/>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription header={true} text={`Total Quantity`}/>
            <TextField label="" source="qty" className="input-180"/>
            <FieldDescription
                instructions={true}
                text={`View the most recent adjustments to this Product's quantity from Orders, Receipts, and manual adjustments.`}
            />
            <InventoryLedgerTable record={props.record} {...props}/>
            <ProductLocationsList record={props.record}/>
            <DateField
                label="Last Modified On:" source="modified"
                options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}
                className="field-bg"/>
            <TextField label="Admin Notes" source="notes" className="field-bg"/>
            <BooleanField label="Active" source="active" className="input-inline input-120"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ProductIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.title}` : 'Product'}</span>;
};

const EditToolbar = (props) => {
    return (
        <Toolbar {...props} classes={useToolbarStyles()}>
            <SaveButton/>
        </Toolbar>
    )
};

export const ProductEdit = (props) => {
    const notify = useNotify();

    const transform = async (data) => {
        let savingImage = !!(data.product_image_input);
        if (
            data.product_image_input ||
            data.inventory_adjustment
        ) {
            let message = 'Saving';
            if (savingImage && data.inventory_adjustment) {
                message = 'Saving Product Image and Quantity Adjustment';
            } else if (savingImage && !data.inventory_adjustment) {
                message = 'Saving Product Image';
            } else if ((data.new_org_id && data.new_org_id !== data.org_id) || (data.new_show_id && data.new_show_id !== data.show_id)) {
                message = 'Updating Product Image';
            } else if (data.inventory_adjustment) {
                message = 'Saving Adjustment to Product Quantity';
            }
            notify(message);
            return await assemblePostTasks(data, onSaveProgress)
                .then((result, ...rest) => {
                    return data;
                })
                .catch(error => {
                    console.log('Posting Error: ', error);
                    notify('Images and Update Error');
                });
        } else {
            return data;
        }
    };

    return (
        <Edit title={<EditTitle/>} undoable={false} transform={transform} {...props}>
            <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text={`
                    Edit Product details below. If this Product is no longer available, mark it as Inactive.
                    `}
                />
                <TextInput disabled label="ID" source="id" formClassName="input-inline input-120"/>
                <TextInput disabled label="SKU #" source="sku" formClassName="input-inline input-280"/>
                <TextInput label="Name of Product" source="title" validate={validateName} fullWidth={true}/>
                <FieldDivider type="break"/>

                <FieldDescription header={true} margin="bottom" text="Client and Title"/>
                <TextInput disabled label="Client" source="org_name" formClassName="input-inline input-320"/>
                <TextInput disabled label="Title" source="show_name" formClassName="input-inline input-320"/>
                <FieldDivider type="break"/>

                <FieldDescription
                    description={true}
                    text={`
                    If this Product should be associated with a different Client, select a new Client. 
                    Otherwise, leave blank. If you select a new Client, you must select a new Title.
                    `}
                />
                <FormDataConsumer formClassName="input-full" {...props}>
                    {({formData, ...rest}) => (
                        <SelectFromListInput
                            inAdminForm={true}
                            source="new_org_id"
                            resourceName="Organization"
                            listLabel="Change Client (optional)"
                            itemPlural="Clients"
                            listFilters={{
                                active: true
                            }}
                            className="input input-full"
                            isRequired={false}
                            returnType="string"
                        />
                    )}
                </FormDataConsumer>
                <FieldDivider type="break" margin="bottom"/>

                <FormDataConsumer formClassName="input-full" {...props}>
                    {({formData, ...rest}) => {
                        let descClient = 'Select a new Title from the new Client.';
                        let descNoClient = 'If this Product should be associated with a different Title from ' + formData.org_name + ', select a new Title. Otherwise, leave blank.';
                        let labelClient = 'Select New Title (required)';
                        let labelNoClient = 'Change Title (optional)';
                        let newOrgIdSelected = !!(formData.new_org_id && formData.new_org_id !== formData.org_id);
                        let newShowIdSelected = !!(formData.new_show_id);
                        return (
                            <>
                                <FieldDescription
                                    description={true}
                                    text={(newOrgIdSelected) ? descClient : descNoClient}
                                />
                                <FieldDivider type="break"/>
                                <SelectFromListInput
                                    inAdminForm={true}
                                    source="new_show_id"
                                    resourceName="ShowByOrg"
                                    listLabel={(newOrgIdSelected) ? labelClient : labelNoClient}
                                    itemPlural="Titles"
                                    listFilters={{
                                        active: true,
                                        org_id: (newOrgIdSelected) ? formData.new_org_id : formData.org_id
                                    }}
                                    className="input-full error"
                                    isRequired={!!(newOrgIdSelected)}
                                    error={(newOrgIdSelected && !newShowIdSelected)}
                                    errorText={(newOrgIdSelected && !newShowIdSelected) ? 'Please select a Title from the new Client.' : ''}
                                    returnType="string"
                                />
                                <FieldDivider type="break"/>
                            </>
                        )
                    }}
                </FormDataConsumer>
                <FieldDivider type="break" margin="bottom"/>

                <FieldDescription
                    description={true}
                    text={`
                    If this Product should be of a different Category, select a new Product Category. 
                    Otherwise, leave blank.
                    `}
                />

                <TextInput disabled label="Category" source="type_display_name"
                           formClassName="input-inline input-320"/>
                <FormDataConsumer formClassName="input-inline input-320" {...props}>
                    {({formData, ...rest}) => (
                        <SelectFromListInput
                            inAdminForm={true}
                            source="new_type_id"
                            resourceName="ProductType"
                            listLabel="Change Category (optional)"
                            itemPlural="Titles"
                            listFilters={{
                                active: true
                            }}
                            className="input-inline input-320"
                            isRequired={false}
                            returnType="string"
                        />
                    )}
                </FormDataConsumer>
                <FieldDivider type="break" margin="bottom"/>

                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <ProductImage renderType="edit" record={formData} allowUpload={true}/>
                    )}
                </FormDataConsumer>

                <TextInput label="Description of Product" source="description" multiline fullWidth={true}/>

                <FieldDivider type="divider" margin="bottom"/>
                <FieldDescription header={true} margin="bottom" text="Inventory"/>
                <FieldDivider type="break" margin="bottom"/>
                <FieldDescription
                    description={true}
                    text={`View the most recent adjustments to this Product's quantity from Orders, Receipts, and manual adjustments.`}
                />
                <FieldDescription
                    description={true}
                    text={`If this Product's quantity needs to be corrected, add an adjustment.`}
                />
                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <>
                            <InventoryLedgerTable record={formData} productId={formData.id} {...props}
                                                  allowAdjustment={true}/>
                        </>
                    )}
                </FormDataConsumer>
                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <>
                            <ProductInventoryAdjustmentInput record={formData}/>
                        </>
                    )}
                </FormDataConsumer>

                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <ProductLocationsInput record={formData}/>
                    )}
                </FormDataConsumer>

                <TextInput label="Admin Notes" source="notes" multiline fullWidth={true}/>
                <SelectInput source="active" label="Active"
                             choices={[
                                 {id: true, name: 'Yes'},
                                 {id: false, name: 'No'},
                             ]}
                             allowEmpty={false}
                />
                <FieldDescription
                    description={true}
                    text="After clicking Save, you will be redirected to the Products List."
                />
            </SimpleForm>
        </Edit>
    )
}
